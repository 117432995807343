<template>
  <div class="profile-container">
    <div class="profile-container-header">
      <span class="title">客户帮助</span>
    </div>
    <div class="profile-container-content">
      <div
        class="profile-container-content-table"
        v-for="item in tableData"
        :key="item.id"
        @click="cellClick(item)"
      >
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getDate } from "@/utils";

export default {
  name: "HelpInfo",
  data() {
    return {
      active: 1,
      tableData: [],
      alarmLoading: false,
    };
  },
  methods: {
    cellClick(row) {
      this.$store.state.helpInfoId = row.id;
      this.$router.push({
        path: `/HelpInfo/detail`,
        query: { title: "客户帮助" },
      });
      // }
    },
    getData() {
      this.alarmLoading = true;
      this.$http
        .findAllHelpInfoIdAndTitle()
        .then((res) => {
          this.tableData = res.data;
          this.alarmLoading = false;
        })
        .catch(() => {
          this.alarmLoading = false;
        });
    },
    getDate,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .profile-container-header {
    width: 100%;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
    //span {
    //  width: 90px;
    //  line-height: 50px;
    //  text-align: center;
    //  font-size: 14px;
    //  color: #333;
    //  cursor: pointer;
    //  border-bottom: 1px solid #eaeaea;
    //  &:nth-child(2) {
    //    border-left: 1px solid #eaeaea;
    //    border-right: 1px solid #eaeaea;
    //  }
    //  &:nth-last-child(1) {
    //    flex: 1;
    //  }
    //}
    //.active {
    //  //background-color: #999;
    //  border-bottom: none;
    //  position: relative;
    //  //border-top: 4px solid #01cfa5;
    //  &::before {
    //    content: "";
    //    width: 90px;
    //    height: 4px;
    //    background-color: #01cfa5;
    //    position: absolute;
    //    left: 0;
    //  }
    //}
  }
  .profile-container-content {
    padding-left: 40px;
    //padding-top: 20px;
    display: flex;
    flex-direction: column;
  }
}
.profile-container-content-table:hover {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #00d4a1;
  color: #00d4a1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 60%;
  .table-left {
    width: 50px;
    height: 50px;
  }
  span {
    margin-left: 20px;
  }
}
.profile-container-content-table {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 60%;
  .table-left {
    width: 50px;
    height: 50px;
  }
  span {
    margin-left: 20px;
  }
}
</style>
